import { ReactNode, MouseEventHandler } from 'react'
import _LazyLoad from './LazyLoad'

const LazyLoad = _LazyLoad as (props: {
  src: string
  mobileSrc?: string
  className?: string
  children?: ReactNode
  type?: string
  width?: string
  height?: string
  onClick?: MouseEventHandler
  url?: string
  external?: boolean
  film?: boolean
}) => JSX.Element

export { default as Head } from './Head'
export { default as Logo } from './Logo'
export { default as Nav } from './Nav'
export { default as Button } from './Button'
export { default as Sales } from './Sales'
export { default as Footer } from './Footer'
export { default as Widget } from './Widget'
export { default as WidgetMobile } from './WidgetMobile'
export { default as SmallPage } from './SmallPage'
export { default as Releases } from './Releases'
export { default as Lots } from './Lots'
export { default as Carousel } from './Carousel'
export { default as WideCarousel } from './WideCarousel'
export { default as Portfolio } from './Portfolio'
export { default as WelcomeHero } from './WelcomeHero'
export { default as Form } from './Form'
export { default as Contact } from './Contact'
export { default as TalkToPresident } from './TalkToPresident'
export { default as RateAttendance } from './RateAttendance'
export { default as Offer } from './Offer'
export { default as PartnerForm } from './PartnerForm'
export { default as BrokerForm } from './BrokerForm'
export { default as Video } from './Video'
export { default as Modal } from './Modal'
export { default as Accordion } from './Accordion'
export { default as Map } from './Map'
export { default as Tabs } from './Tabs'
export { default as Lightbox } from './Lightbox'
export { default as Blog } from './Blog'
export { default as Link } from './Link'
export { default as Enterprise } from './Enterprise'
export { default as Loading } from './Loading'
export { LazyLoad }
