import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useBlogApi } from '~/siteApi'
import { Carousel, LazyLoad } from '~/components'
import * as st from '~/assets/styl/Blog.module.styl'

function formatDate(dateStr: string) {
  const date = new Date(dateStr)

  return `${date.getDate()} de ${
    [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ][date.getMonth()]
  } de ${date.getFullYear()}`
}

const Blog = () => {
  const posts = useBlogApi(
    // useStaticQuery(graphql`
    //   query {
    //     allPosts {
    //       nodes {
    //         date
    //         title
    //         thumbnail
    //         link
    //       }
    //     }
    //   }
    // `).allPosts.nodes,
    [],
    'posts?_embed'
  )

  return posts.length ? (
    <section className={st.core}>
      <div className="container">
        <h3>Blog Alínea Urbanismo</h3>
        <p>
          Acompanhe aqui as últimas novidades dos conteúdos postados no Blog da
          Alínea Urbanismo
        </p>

        <Carousel slidesToShow={2} className={st.carousel}>
          {posts.map(({ date, title, thumbnail, _embedded, link }, key) => (
            <a
              className={st.slide}
              key={key}
              href={link}
              target="_blank"
              rel="noreferrer"
            >
              <LazyLoad
                className={st.thumbnail}
                src={
                  thumbnail || _embedded?.['wp:featuredmedia']?.[0].source_url
                }
              />
              <div>
                <p>{formatDate(date)}</p>
                <h4>{title}</h4>
              </div>
            </a>
          ))}
        </Carousel>
      </div>
    </section>
  ) : (
    <></>
  )
}

export default Blog
