export default [
  {
    id: 'Acre (AC)',
    name: 'Acre',
  },
  {
    id: 'Alagoas (AL)',
    name: 'Alagoas',
  },
  {
    id: 'Amapá (AP)',
    name: 'Amapá',
  },
  {
    id: 'Amazonas (AM)',
    name: 'Amazonas',
  },
  {
    id: 'Acre (AC)',
    name: 'Acre',
  },
  {
    id: 'Bahia (BA)',
    name: 'Bahia',
  },
  {
    id: 'Ceará (CE)',
    name: 'Ceará',
  },
  {
    id: 'Distrito Federal (DF)',
    name: 'Distrito Federal',
  },
  {
    id: 'Espírito Santo (ES)',
    name: 'Espírito Santo',
  },
  {
    id: 'Goiás (GO)',
    name: 'Goiás',
  },
  {
    id: 'Maranhão (MA)',
    name: 'Maranhão',
  },
  {
    id: 'Mato Grosso (MT)',
    name: 'Mato Grosso',
  },
  {
    id: 'Mato Grosso do Sul (MS)',
    name: 'Mato Grosso do Sul',
  },
  {
    id: 'Minas Gerais (MG)',
    name: 'Minas Gerais',
  },
  {
    id: 'Pará (PA)',
    name: 'Pará',
  },
  {
    id: 'Paraíba (PB)',
    name: 'Paraíba',
  },
  {
    id: 'Paraná (PR)',
    name: 'Paraná',
  },
  {
    id: 'Pernambuco (PE)',
    name: 'Pernambuco',
  },
  {
    id: 'Piauí (PI)',
    name: 'Piauí',
  },
  {
    id: 'Rio de Janeiro (RJ)',
    name: 'Rio de Janeiro',
  },
  {
    id: 'Rio Grande do Norte (RN)',
    name: 'Rio Grande do Norte',
  },
  {
    id: 'Rio Grande do Sul (RS)',
    name: 'Rio Grande do Sul',
  },
  {
    id: 'Rondônia (RO)',
    name: 'Rondônia',
  },
  {
    id: 'Roraima (RR)',
    name: 'Roraima',
  },
  {
    id: 'Santa Catarina (SC)',
    name: 'Santa Catarina',
  },
  {
    id: 'São Paulo (SP)',
    name: 'São Paulo',
  },
  {
    id: 'Sergipe (SE)',
    name: 'Sergipe',
  },
  {
    id: 'Tocantins (TO)',
    name: 'Tocantins',
  },
]
