import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
 from { opacity: 0; }
 to { opacity: 1; }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 0.5s;
  animation: ${fadeIn} 0.5s;

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

type ModalProps = {
  children: React.ReactNode
  onClose: () => void
  className: string
}

const Modal = ({ children, onClose, className }: ModalProps) => {
  const [opacity, setOpacity] = useState(1)
  const close = () => {
    setOpacity(0)
    setTimeout(onClose, 500)
  }

  return (
    <Overlay
      style={{ opacity }}
      onClick={(e: React.MouseEvent) => {
        if (e.currentTarget === e.target) close()
      }}
    >
      <div className={className}>
        <button className="modal-close" onClick={close}></button>
        <div className="modal-content">{children}</div>
      </div>
    </Overlay>
  )
}

type Props = {
  children: React.ReactNode
  open: boolean
  onClose: () => void
  className: string
}

const OVERLAY_ID = 'react-modal-overlay'

const ModalRenderer = ({ children, open, onClose, className }: Props) => {
  useEffect(() => {
    if (open && !document.getElementById(OVERLAY_ID)) {
      const container = document.createElement('DIV')
      container.id = OVERLAY_ID
      document.body.appendChild(container)
    }
    try {
      ReactDOM.render(
        open ? (
          <Modal onClose={onClose} className={className}>
            {children}
          </Modal>
        ) : (
          <></>
        ),
        document.getElementById(OVERLAY_ID)
      )
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, [open])

  return <></>
}

export default ModalRenderer
