import axios from 'axios'

export default (method, path, data = null, config = {}) => {
  const apiUrl = `https://blog.alineaurbanismo.com.br/wp-json/wp/v2/${path}`
  return axios[method](apiUrl, data, config)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response && error.response.data) throw error.response.data
      throw error
    })
}
