import React, { useEffect, useState } from 'react'
import {
  GoogleMap as _GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps'
import * as st from '~/assets/styl/Map.module.styl'
import shadow from '~/assets/svg/Marcador.svg'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const windowGlobal: any = typeof window !== 'undefined' ? window : {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GoogleMap = _GoogleMap as any

type Props = {
  location: { lat: number; lng: number }
  pin?: string
}

const GMap = withScriptjs(
  withGoogleMap(({ location, pin }: Props) => {
    const [pinSize, setPinSize] = useState<{ x: number; y: number }>()
    useEffect(() => {
      const pinImage = new Image()
      pinImage.src = pin
      pinImage.onload = () => {
        const { width, height } = pinImage
        setPinSize({
          x: 0.55 * 75,
          y: 0.55 * ((75 / width) * height),
        })
      }
    }, [pin])

    return (
      <GoogleMap
        defaultZoom={13}
        defaultCenter={location}
        options={{
          fullscreenControl: false,
          zoomControl: false,
          mapTypeControl: false,
          streetViewControl: false,
        }}
      >
        {pinSize && (
          <>
            <Marker
              icon={{
                url: shadow,
                anchor: new windowGlobal.google.maps.Point(37.5, 84),
                size: new windowGlobal.google.maps.Size(75, 84),
              }}
              clickable={false}
              position={location}
            />
            <Marker
              icon={{
                url: pin,
                anchor: new windowGlobal.google.maps.Point(
                  pinSize.x / 2,
                  pinSize.y + 9 + (75 - pinSize.y) / 2
                ),
                scaledSize: new windowGlobal.google.maps.Size(
                  pinSize.x,
                  pinSize.y
                ),
              }}
              clickable={false}
              position={location}
            />
          </>
        )}
      </GoogleMap>
    )
  })
)

const Map = (props: Props) => (
  <GMap
    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.GATSBY_GMAPS_KEY}`}
    loadingElement={<></>}
    containerElement={<div className={st.core}></div>}
    mapElement={<div className={st.map}></div>}
    {...props}
  />
)

export default Map
