import React from 'react'
import { Button, LazyLoad } from '~/components'
import * as st from '~/assets/styl/SmallPage.module.styl'
import Logo from '~/assets/svg/Logo.svg'

type Props = {
  banner: string
  title: string
  outlinedText: string
  outlinedTextMobile: string
  message: React.ReactNode
  buttonLabel: string
}

const SmallPage = ({
  banner,
  title,
  outlinedText,
  outlinedTextMobile,
  message,
  buttonLabel,
}: Props) => (
  <section className={st.core}>
    <div className={st.header}>
      <div className="container">
        <h1>{title}</h1>
        <LazyLoad className={st.thumbnail} src={banner} />
        <LazyLoad className={st.outlinedText} src={outlinedText}>
          <LazyLoad src={outlinedTextMobile} />
        </LazyLoad>
      </div>
    </div>
    <div className={st.content}>
      <div className="container">
        <img src={Logo} width="200" />
        <p>{message}</p>
        <Button to="/">{buttonLabel}</Button>
      </div>
    </div>
  </section>
)

export default SmallPage
