import React, { Children, useState } from 'react'
import cn from 'classnames'
import * as st from '~/assets/styl/Tabs.module.styl'

type Props = {
  items: Array<string>
  default: number
  children: React.ReactNode
}

const Tabs = ({ items, default: defaultTab, children }: Props) => {
  const [activeTab, setActiveTab] = useState(defaultTab)

  return (
    <>
      <div className="container">
        <div className={st.tabs}>
          {items.map((tab, key) => (
            <button
              className={cn(activeTab === key && st.active)}
              key={key}
              onClick={() => setActiveTab(key)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      <div className={st.content}>
        {Children.map(children, (child, key) => (
          <div key={key} className={cn(activeTab === key && st.active)}>
            {child}
          </div>
        ))}
      </div>
    </>
  )
}

export default Tabs
