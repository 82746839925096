import React, { LegacyRef } from 'react'
import { Link as GatsbyLink } from 'gatsby'

type Props = {
  to: string
  external?: boolean
  children?: React.ReactNode
  className?: string
  onClick?: React.MouseEventHandler
}

const Link = React.forwardRef(
  ({ to, external, children, ...props }: Props, ref) =>
    external ? (
      <a
        href={to}
        target="_blank"
        rel="noreferrer"
        {...props}
        ref={ref as LegacyRef<HTMLAnchorElement>}
      >
        {children}
      </a>
    ) : (
      <GatsbyLink
        to={to.replace(/.*\/\/[^/]*/, '')}
        {...props}
        ref={
          ref as LegacyRef<GatsbyLink<unknown>> & LegacyRef<HTMLAnchorElement>
        }
      >
        {children}
      </GatsbyLink>
    )
)
Link.displayName = 'Link'

export default Link
