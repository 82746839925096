import React from 'react'

type Props = {
  pathsClassName?: string
}

const Logo = ({ pathsClassName }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 154.9 45">
    <defs>
      <linearGradient
        id="linear-gradient"
        y1="0.5"
        x2="1"
        y2="0.515"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#e5252e" />
        <stop offset="0.045" stopColor="#e5252e" />
        <stop offset="1" stopColor="#731317" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="1"
        y1="0.484"
        x2="0"
        y2="0.48"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#e5252e" />
        <stop offset="1" stopColor="#731317" />
      </linearGradient>
    </defs>
    <g transform="translate(-244 -71)">
      <path
        d="M35.6,67.267A17.433,17.433,0,1,0,18.1,49.833,17.462,17.462,0,0,0,35.6,67.267Zm0-23.133a5.8,5.8,0,1,1-5.833,5.8A5.811,5.811,0,0,1,35.6,44.133Z"
        transform="translate(225.9 38.733)"
        fill="url(#linear-gradient)"
      />
      <path
        d="M45.067,55.067a5.833,5.833,0,1,1,4.267,0,11.7,11.7,0,1,0-4.267,0Z"
        transform="translate(214.3 39)"
        fill="url(#linear-gradient-2)"
      />
      <g className={pathsClassName} transform="translate(285.83 71.687)">
        <path
          d="M153.794,61.8c.833,0,1.767.033,2.867.133a6.524,6.524,0,0,1,5.467,3.667,11.806,11.806,0,0,1,1,5.4V84.4H161.46a6.394,6.394,0,0,1-2.233-.367,2.392,2.392,0,0,1-1.367-1.467,8.035,8.035,0,0,1-1.8,1.4,7.241,7.241,0,0,1-3.367.833,13.222,13.222,0,0,1-3.433-.367,8.489,8.489,0,0,1-2.933-1.367,6.753,6.753,0,0,1-2.033-2.4,7.042,7.042,0,0,1-.7-3.433A5.683,5.683,0,0,1,147.46,71.6c2.533-.967,6-1.1,10.433-.4a6.061,6.061,0,0,0-.1-2.7,4.184,4.184,0,0,0-1.2-1.9,4.454,4.454,0,0,0-3.033-.867,18.023,18.023,0,0,0-3.5.3,13.627,13.627,0,0,1-2.5.333,1.941,1.941,0,0,1-1.533-.567,4.055,4.055,0,0,1-.533-2.467,17.69,17.69,0,0,1,2.333-.7c.733-.2,1.6-.367,2.6-.533A18.072,18.072,0,0,1,153.794,61.8ZM152.76,80.6a7.98,7.98,0,0,0,1.967-.267,3.893,3.893,0,0,0,1.7-.933,4.2,4.2,0,0,0,1.133-1.833,8.473,8.473,0,0,0,.367-2.967,13.778,13.778,0,0,0-1.467-.333c-.433-.067-1-.133-1.633-.2a13.566,13.566,0,0,0-2.033-.033,10.408,10.408,0,0,0-1.367.2,3.77,3.77,0,0,0-1.3.567,2.878,2.878,0,0,0-.967,1.033,2.8,2.8,0,0,0-.367,1.567,3.546,3.546,0,0,0,.3,1.567,2.431,2.431,0,0,0,.867.967,3.779,3.779,0,0,0,1.3.5A5.166,5.166,0,0,0,152.76,80.6Z"
          transform="translate(-143.591 -52.553)"
          fill="#6e6e73"
        />
        <path
          d="M218.233,37.833a3.362,3.362,0,0,1,1.267.567,2.764,2.764,0,0,1,.833,1.167,5.094,5.094,0,0,1,.3,1.867V68.3h-1.667a7.628,7.628,0,0,1-1.567-.133,3.362,3.362,0,0,1-1.267-.567,2.765,2.765,0,0,1-.833-1.167,5.094,5.094,0,0,1-.3-1.867V37.7h1.667A10.994,10.994,0,0,1,218.233,37.833Z"
          transform="translate(-191.197 -36.487)"
          fill="#6e6e73"
        />
        <path
          d="M249.233,34.606a1.6,1.6,0,0,1,.4,1.767,3.853,3.853,0,0,1-1.7,1.867,12.042,12.042,0,0,1-3.467,1.467,15.845,15.845,0,0,1-4.867.5,22.736,22.736,0,0,0,2.3-2.067,33.434,33.434,0,0,1,2.6-2.367,9.653,9.653,0,0,1,2.567-1.533A1.9,1.9,0,0,1,249.233,34.606Zm-5.8,9.133a7.627,7.627,0,0,1,1.567.133,3.308,3.308,0,0,1,1.267.533,2.655,2.655,0,0,1,.833,1.133,5.094,5.094,0,0,1,.3,1.867V65.873h-1.667a7.628,7.628,0,0,1-1.567-.133,3.362,3.362,0,0,1-1.267-.567,2.764,2.764,0,0,1-.833-1.167,5.094,5.094,0,0,1-.3-1.867V43.74Z"
          transform="translate(-207.597 -34.06)"
          fill="#6e6e73"
        />
        <path
          d="M287.8,61.743a9.846,9.846,0,0,1,3.767.467,8.716,8.716,0,0,1,3.433,2,11.128,11.128,0,0,1,2.5,3.667,13.837,13.837,0,0,1,.967,5.4v11.1H296.8a7.628,7.628,0,0,1-1.567-.133,3.362,3.362,0,0,1-1.267-.567,2.765,2.765,0,0,1-.833-1.167,5.094,5.094,0,0,1-.3-1.867V73.51a15.648,15.648,0,0,0-.367-3.633,6.134,6.134,0,0,0-1-2.3,3.639,3.639,0,0,0-1.5-1.2,5.649,5.649,0,0,0-1.867-.4,5.218,5.218,0,0,0-2,.3,4.352,4.352,0,0,0-1.767,1.133,5.768,5.768,0,0,0-1.233,2.2,10.3,10.3,0,0,0-.467,3.433V84.41h-1.667a7.628,7.628,0,0,1-1.567-.133,3.362,3.362,0,0,1-1.267-.567,2.764,2.764,0,0,1-.833-1.167,5.094,5.094,0,0,1-.3-1.867v-18.5h1.667a6.277,6.277,0,0,1,2.367.4,2.347,2.347,0,0,1,1.367,1.7q.2-.2.7-.6a11.782,11.782,0,0,1,1.167-.8,8.169,8.169,0,0,1,1.533-.7A11.343,11.343,0,0,1,287.8,61.743Z"
          transform="translate(-232.53 -52.497)"
          fill="#6e6e73"
        />
        <path
          d="M372.2,69.158a7.567,7.567,0,0,1-.8,3.2,5.706,5.706,0,0,1-2.433,2.433,11.007,11.007,0,0,1-4.533,1.133,25.515,25.515,0,0,1-7.1-.767,7.24,7.24,0,0,0,1.7,4.233,4.77,4.77,0,0,0,3.333,1.333,29.239,29.239,0,0,0,3.6-.2,13.457,13.457,0,0,1,2.767-.1,2.846,2.846,0,0,1,1.733.667A2.791,2.791,0,0,1,371,83.225a14.734,14.734,0,0,1-2.333.767,22.046,22.046,0,0,1-2.767.5,31.739,31.739,0,0,1-3.767.2,12.3,12.3,0,0,1-3.933-.633,9.061,9.061,0,0,1-3.433-1.967,10.247,10.247,0,0,1-2.433-3.533,13.4,13.4,0,0,1-.933-5.333,12.845,12.845,0,0,1,.933-5.267,9.656,9.656,0,0,1,2.433-3.467,9.523,9.523,0,0,1,3.433-1.967,16.176,16.176,0,0,1,3.933-.8,11.6,11.6,0,0,1,3.7.3,10.255,10.255,0,0,1,3.267,1.367,7.634,7.634,0,0,1,2.333,2.4A5.931,5.931,0,0,1,372.2,69.158Zm-14.867,2.233a28.315,28.315,0,0,0,3.6.7,10.187,10.187,0,0,0,2.9-.1,4.027,4.027,0,0,0,2-.967,3.168,3.168,0,0,0,0-4.467,4.343,4.343,0,0,0-3.467-.967,4.749,4.749,0,0,0-3.333,1.467A7.151,7.151,0,0,0,357.333,71.392Z"
          transform="translate(-282.13 -52.478)"
          fill="#6e6e73"
        />
        <path
          d="M432.094,61.8c.833,0,1.767.033,2.867.133a6.524,6.524,0,0,1,5.467,3.667,11.806,11.806,0,0,1,1,5.4V84.4H439.76a6.394,6.394,0,0,1-2.233-.367,2.392,2.392,0,0,1-1.367-1.467,8.036,8.036,0,0,1-1.8,1.4,7.241,7.241,0,0,1-3.367.833,13.222,13.222,0,0,1-3.433-.367,8.489,8.489,0,0,1-2.933-1.367,6.752,6.752,0,0,1-2.033-2.4,7.041,7.041,0,0,1-.7-3.433A5.683,5.683,0,0,1,425.76,71.6c2.533-.967,6-1.1,10.433-.4a6.06,6.06,0,0,0-.1-2.7,4.184,4.184,0,0,0-1.2-1.9,4.454,4.454,0,0,0-3.033-.867,18.023,18.023,0,0,0-3.5.3,13.626,13.626,0,0,1-2.5.333,1.942,1.942,0,0,1-1.533-.567,4.055,4.055,0,0,1-.533-2.467,17.69,17.69,0,0,1,2.333-.7c.733-.2,1.6-.367,2.6-.533A17.815,17.815,0,0,1,432.094,61.8ZM431.06,80.6a7.98,7.98,0,0,0,1.967-.267,3.893,3.893,0,0,0,1.7-.933,4.2,4.2,0,0,0,1.133-1.833,8.472,8.472,0,0,0,.367-2.967,13.778,13.778,0,0,0-1.467-.333c-.433-.067-1-.133-1.633-.2a13.566,13.566,0,0,0-2.033-.033,10.407,10.407,0,0,0-1.367.2,3.77,3.77,0,0,0-1.3.567,2.877,2.877,0,0,0-.967,1.033,2.8,2.8,0,0,0-.367,1.567,3.546,3.546,0,0,0,.3,1.567,2.431,2.431,0,0,0,.867.967,3.779,3.779,0,0,0,1.3.5A5.166,5.166,0,0,0,431.06,80.6Z"
          transform="translate(-329.124 -52.553)"
          fill="#6e6e73"
        />
      </g>
      <g className={pathsClassName} transform="translate(343.667 108.033)">
        <path
          d="M317.9,148.6a2.662,2.662,0,0,0,.3,1.6,1.883,1.883,0,0,0,1.467.7,1.845,1.845,0,0,0,1.4-.633,2.629,2.629,0,0,0,.367-1.667v-4.3h.8v4.533a2.555,2.555,0,0,1-.633,2,2.647,2.647,0,0,1-1.933.833,2.8,2.8,0,0,1-1.867-.733,2.548,2.548,0,0,1-.7-2.1V144.3h.8Z"
          transform="translate(-317.1 -143.9)"
          fill="#ff001e"
        />
        <path
          d="M337.6,151.533h-.8V144.3h1.033c1.367,0,2.633.367,2.633,2a1.9,1.9,0,0,1-1.8,2l2.3,3.233h-1l-2.167-3.167h-.2Zm0-3.9h.233c.9,0,1.833-.167,1.833-1.3,0-1.2-.867-1.3-1.833-1.3H337.6Z"
          transform="translate(-330.233 -143.9)"
          fill="#ff001e"
        />
        <path
          d="M352.4,144.3h1.2c1.3,0,2.4.467,2.4,1.967a1.622,1.622,0,0,1-.7,1.4,1.825,1.825,0,0,1,1.367,1.833c0,1.433-1.167,2-2.6,2H352.4v-7.2Zm.8,3.2h.3c.9,0,1.7-.1,1.7-1.233,0-1.1-.7-1.2-1.667-1.2h-.3V147.5Zm0,3.267h.8c.9,0,1.833-.233,1.833-1.333,0-1.133-1.067-1.267-1.967-1.267h-.7v2.6Z"
          transform="translate(-340.633 -143.9)"
          fill="#ff001e"
        />
        <path
          d="M368.433,148.733l-.867,2.033H366.7l3.333-7.567,3.233,7.567h-.9l-.867-2.033ZM370,145.1l-1.233,2.9H371.2Z"
          transform="translate(-350.167 -143.167)"
          fill="#ff001e"
        />
        <path
          d="M388.9,143.4l5.633,5.9v-5.6h.8v7.567l-5.633-5.9v5.567h-.8Z"
          transform="translate(-364.967 -143.3)"
          fill="#ff001e"
        />
        <path
          d="M413.6,151.533h-.8V144.3h.8Z"
          transform="translate(-380.9 -143.9)"
          fill="#ff001e"
        />
        <path
          d="M422.2,145.433a1.345,1.345,0,0,0-1.233-.8,1.16,1.16,0,0,0-1.2,1.133c0,.633.633.9,1.133,1.133l.467.2c.933.4,1.733.867,1.733,2.033a2.22,2.22,0,0,1-2.267,2.233A2.175,2.175,0,0,1,418.6,149.5l.8-.233a1.471,1.471,0,0,0,2.933-.067c0-.833-.667-1.133-1.333-1.433l-.433-.2c-.833-.367-1.567-.8-1.567-1.833a1.879,1.879,0,0,1,2.033-1.833,2.026,2.026,0,0,1,1.867,1.133Z"
          transform="translate(-384.767 -143.633)"
          fill="#ff001e"
        />
        <path
          d="M435.467,143.1l2.633,6.067,2.633-6.067,1.467,7.633h-.833l-.933-4.867H440.4l-2.333,5.167-2.3-5.167h-.033l-.933,4.867H434Z"
          transform="translate(-395.033 -143.1)"
          fill="#ff001e"
        />
        <path
          d="M467.733,147.733A3.767,3.767,0,1,1,463.967,144,3.756,3.756,0,0,1,467.733,147.733Zm-6.7,0a2.967,2.967,0,1,0,2.967-3A2.959,2.959,0,0,0,461.033,147.733Z"
          transform="translate(-412.5 -143.7)"
          fill="#ff001e"
        />
      </g>
    </g>
  </svg>
)

export default Logo
