import React from 'react'
import { LazyLoad } from '~/components'
import * as st from '~/assets/styl/WelcomeHero.module.styl'

type Props = {
  title: string
  banner: string
}

const WelcomeHero = ({ title, banner }: Props) => (
  <header className={st.core}>
    <div className="container">
      <h1>{title}</h1>
      <LazyLoad src={banner} className={st.banner} />
    </div>
  </header>
)

export default WelcomeHero
