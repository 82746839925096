import React from 'react'
import { Carousel, LazyLoad } from '~/components'
import { Link } from 'gatsby'
import * as st from '~/assets/styl/Portfolio.module.styl'
import * as st1 from '~/assets/styl/Lots.module.styl'

type Props = {
  items: Array<{
    imageFeatureDesktop: string
    imageFeatureMobile: string
    name: string
    slug: string
    metreage: string
    tag: string
    neighborhood: string
    city: string
    state: string
  }>
  id?: string
}

const Portfolio = ({ items, id }: Props) => {
  return (
    <>
      <section id={id}>
        <div className="container">
          <h3>Portfólio</h3>
          <p>Conheça os empreendimentos 100% vendidos da Alínea</p>
          <Carousel>
            {items.map(
              (
                {
                  name,
                  neighborhood,
                  city,
                  state,
                  tag,
                  slug,
                  imageFeatureDesktop,
                  imageFeatureMobile,
                },
                key
              ) => (
                <Link
                  to={'/empreendimento/' + slug + '/'}
                  className={st.slide}
                  key={key}
                >
                  <LazyLoad
                    className={st.thumbnail}
                    src={imageFeatureDesktop}
                    mobileSrc={imageFeatureMobile}
                  />
                  <h4>{name}</h4>
                  <p className={st.location}>
                    {neighborhood} - {city} - {state}
                  </p>
                  {tag && <span className={st1.highlight}>{tag}</span>}
                </Link>
              )
            )}
          </Carousel>
        </div>
      </section>
    </>
  )
}

export default Portfolio
