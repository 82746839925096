import React, { useRef, useState, useEffect } from 'react'
import { LazyLoad } from '~/components'
import Helmet from 'react-helmet'
import cn from 'classnames'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const windowGlobal: any = typeof window !== 'undefined' ? window : {}

function getVideoId(url: string) {
  const urlParts = url.split(/(youtu\.be\/)|(youtube\.com\/watch\?v=)/)
  return urlParts[urlParts.length - 1]
}

type Props = {
  url: string
  thumbnail: string
  className: string
}

const Video = ({ url, thumbnail, className }: Props) => {
  const ref = useRef()
  const [playing, setPlaying] = useState(0)

  useEffect(() => {
    if (playing !== 1) return
    const f = setInterval(() => {
      if (!windowGlobal.YT.Player) return
      const player = new windowGlobal.YT.Player(ref.current, {
        events: {
          onReady: () => player.playVideo(),
          onStateChange: ({ data: code }) => {
            if (code === 1) setPlaying(2)
          },
        },
      })
      clearInterval(f)
    }, 100)
  }, [playing])

  return (
    <>
      <Helmet>
        <script src="https://www.youtube.com/iframe_api"></script>
      </Helmet>

      <div
        className={cn(
          className,
          playing === 1 && 'loading',
          playing === 2 && 'playing'
        )}
        onClick={() => playing === 0 && setPlaying(1)}
      >
        <LazyLoad className="thumbnail" src={thumbnail} />
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${getVideoId(url)}?enablejsapi=1`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          ref={ref}
        ></iframe>
      </div>
    </>
  )
}

export default Video
