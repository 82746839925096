import React from 'react'
import { scroller } from 'react-scroll'
import { Link } from 'gatsby'
import cn from 'classnames'
import * as st from '~/assets/styl/Button.module.styl'

type Props = {
  children?: React.ReactNode
  to?: string
  scroll?: boolean
  external?: boolean
  download?: boolean
  buttonType?: 'button' | 'submit' | 'reset'
  className?: string
  type?: string
  onClick?: React.MouseEventHandler
  disabled?: boolean
}

const Children = (children: React.ReactNode, type: string) => (
  <>
    {Boolean(!type || type === 'small' || type === 'newsletterButton') && (
      <span className="icon-arrow"></span>
    )}
    {children}
    {Boolean(!type || type === 'small' || type === 'newsletterButton') && (
      <span className="icon-arrow"></span>
    )}
  </>
)

const Button = ({
  children,
  to,
  scroll,
  external,
  download,
  buttonType,
  className,
  type = '',
  onClick,
  disabled,
}: Props) => {
  const scrollTo: React.MouseEventHandler = (e) => {
    e.preventDefault()

    scroller.scrollTo(to.split('#')[1], {
      duration: 500,
      smooth: true,
    })
  }

  return to ? (
    scroll || external || download ? (
      <a
        href={to}
        target={external && '_blank'}
        download={download}
        className={cn(
          st.core,
          type.split(' ').map((type) => st[type]),
          className
        )}
        rel="noreferrer"
        onClick={scroll ? scrollTo : onClick}
      >
        {Children(children, type)}
      </a>
    ) : (
      <Link
        to={to}
        className={cn(
          st.core,
          type.split(' ').map((type) => st[type]),
          className
        )}
      >
        {Children(children, type)}
      </Link>
    )
  ) : (
    <button
      className={cn(
        type !== 'arrow' && st.core,
        type.split(' ').map((type) => st[type]),
        className
      )}
      type={buttonType}
      onClick={onClick}
      disabled={disabled}
    >
      {Children(children, type)}
    </button>
  )
}

export default Button
