import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import cn from 'classnames'

const Header = styled.div`
  cursor: pointer;
`

const Content = styled.div`
  transition: max-height 0.3s;
  overflow: hidden;
`

type Props = {
  header: React.ReactNode
  children: React.ReactNode
  open?: boolean
  className: string
}

const Accordion = ({
  header,
  children,
  open: openDefault,
  className,
}: Props) => {
  const contentRef = useRef<HTMLDivElement>()
  const [open, setOpen] = useState(openDefault)
  const [maxHeight, setMaxHeight] = useState<number>(openDefault ? null : 0)
  const [locked, setLocked] = useState(false)

  useEffect(() => {
    if (!contentRef.current) return
    setLocked(true)
    if (!open) {
      setMaxHeight(contentRef.current.offsetHeight)
      setTimeout(() => {
        setMaxHeight(0)
        setLocked(false)
      }, 50)
    } else {
      setMaxHeight(contentRef.current.offsetHeight)
      setTimeout(() => {
        setMaxHeight(null)
        setLocked(false)
      }, 300)
    }
  }, [open])

  return (
    <div className={cn(className, open && 'open')}>
      <Header onClick={() => !locked && setOpen(!open)}>{header}</Header>
      <Content
        style={maxHeight !== null ? { maxHeight: `${maxHeight}px` } : null}
      >
        <div ref={contentRef}>{children}</div>
      </Content>
    </div>
  )
}

export default Accordion
