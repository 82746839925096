import React, { useState, useEffect } from 'react'
import Slider from '@fil1pe/react-slider'
import { Button } from '~/components'
import cn from 'classnames'
import * as st from '~/assets/styl/Carousel.module.styl'

type Props = {
  children: React.ReactNode
  slidesToShow?: number
  slidesToShowMobile?: number
  className?: string
  matchMedia?: number
}

const Carousel = ({
  children,
  slidesToShow: _slidesToShow = 3,
  slidesToShowMobile = 1,
  className,
  matchMedia = 1300,
}: Props) => {
  const [slidesToShow, setSlidesToShow] = useState(slidesToShowMobile)
  useEffect(() => {
    const onResize = () => {
      if (window.matchMedia(`(max-width: ${matchMedia}px)`).matches)
        setSlidesToShow(slidesToShowMobile)
      else setSlidesToShow(_slidesToShow)
    }
    window.addEventListener('resize', onResize)
    onResize()
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <Slider
      slidesToShow={slidesToShow}
      className={cn(st.core, className)}
      finite
      renderArrow={({ onClick, className }) => (
        <Button
          onClick={onClick}
          className={cn(className, st.arrow)}
          type="arrow"
        ></Button>
      )}
    >
      {children}
    </Slider>
  )
}

export default Carousel
